import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import { Container } from "react-bootstrap";

import casalbarbas from "../../assets/images/partners/logo_barbas.jpg";
import figueiredo from "../../assets/images/partners/logo_figueiredo.jpg";
import furadouro from "../../assets/images/partners/logo-associacao.jpg";

import { FaFacebookSquare } from "react-icons/fa";
import { RiRoadMapFill } from "react-icons/ri";

import "./partners.css";

const Partners = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <Container className='test_content'>
        <div className='partners_content'>
          <div className='partners-header'>
            <h1 className='partners-title'>{"Localizações"}</h1>
          </div>
          <div className='partners-list'>
            <div className='partner-box'>
              <img className='partners-image' src={furadouro} alt='food' />
              <div className='partners-info'>
                <h1 className='partners-text'>
                  Associação Cultural Recreativa e Desportiva do Furadouro
                </h1>
                <div className='partners-icons'>
                  <a
                    className='icons'
                    href='https://www.facebook.com/FuradouroTorresVedras'
                  >
                    <FaFacebookSquare size={30} />
                  </a>
                  <a
                    className='icons'
                    href='https://goo.gl/maps/2Tm2kufDuvGsPXCB8'
                  >
                    <RiRoadMapFill size={30} />
                  </a>
                </div>
              </div>
            </div>
            <div className='partner-box'>
              <img className='partners-image' src={figueiredo} alt='food' />
              <div className='partners-info'>
                <h1 className='partners-text'>
                  Associação Recreativa e Cultural Figueiredo
                </h1>
                <div className='partners-icons'>
                  <a
                    className='icons'
                    href='https://www.facebook.com/profile.php?id=100068691261990'
                  >
                    <FaFacebookSquare size={30} />
                  </a>
                  <a
                    className='icons'
                    href='https://goo.gl/maps/i5dfixwSNyU6dS2v6'
                  >
                    <RiRoadMapFill size={30} />
                  </a>
                </div>
              </div>
            </div>
            <div className='partner-box'>
              <img className='partners-image' src={casalbarbas} alt='food' />
              <div className='partners-info'>
                <h1 className='partners-text'>
                  Centro Cultural e Recreativo de Casas Barbas
                </h1>
                <div className='partners-icons'>
                  <a
                    className='icons'
                    href='https://www.facebook.com/casalde.barbas'
                  >
                    <FaFacebookSquare size={30} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footerbar />
    </div>
  );
};

export default Partners;
