import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import food from "../../assets/images/activities/food.png";
import brain from "../../assets/images/activities/brain.png";
import community from "../../assets/images/activities/community.png";
import healthcare from "../../assets/images/activities/healthcare.png";
import massage from "../../assets/images/activities/massage.png";
import walk from "../../assets/images/activities/walk.png";
import workshops from "../../assets/images/activities/workshops.png";

import "./activities.css";

const Activities = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <div className='default-background'>
        <div className='content'>
          <div className='activities'>
            <div className='activities-title'>
              <h1>Actividades</h1>
            </div>
            <div className='activities-grid'>
              <div className='image-box'>
                <div className='label'>
                  <label>Acompanhamneto Nutricional</label>
                </div>
                <img className='image-activity' src={food} alt='food' />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Estimulo de Trabalho Individual</label>
                </div>
                <img className='image-activity' src={brain} alt='brain' />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Aulas de Grupo</label>
                </div>
                <img
                  className='image-activity'
                  src={community}
                  alt='community'
                />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Acompanhamento Medico</label>
                </div>
                <img
                  className='image-activity'
                  src={healthcare}
                  alt='healthcare'
                />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Massagem de Relaxamento</label>
                </div>
                <img className='image-activity' src={massage} alt='massage' />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Caminhadas Informais</label>
                </div>
                <img className='image-activity' src={walk} alt='walk' />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>Educação para a Saúde</label>
                </div>
                <img
                  className='image-activity'
                  src={workshops}
                  alt='workshops'
                />
              </div>
              <div className='image-box'>
                <div className='label'>
                  <label>???????</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
