import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import { Container } from "react-bootstrap";

import cmtv from "../../assets/images/supporters/logo-cmtv.png";
import esel from "../../assets/images/supporters/logo-esel.svg";
import farmacia2p from "../../assets/images/supporters/logo-farmacia2p.jpg";
import freguesia_doisportos from "../../assets/images/supporters/logo-doisportos.gif";
import freguesia_smsm from "../../assets/images/supporters/logo-smariasmiguel.gif";
import freguesia_spst from "../../assets/images/supporters/logo-spedrosantiago.gif";
import freguesia_matacaes from "../../assets/images/supporters/logo-matacaes.gif";
import freguesia_runa from "../../assets/images/supporters/logo-runa.gif";
import gil from "../../assets/images/supporters/logo-GilGomes.jpeg";
import joana from "../../assets/images/supporters/logo-joana.jpeg";
import serenaindade from "../../assets/images/supporters/logo-serenaidade.jpeg";

import "./supporters.css";

const Supporters = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <Container className='test_content'>
        <div className='supporters_content'>
          <div className='supporters-header'>
            <h1 className='supporters-title'>{"Apoios"}</h1>
          </div>
          <div className='supporters-list'>
            <div className='supporters-row'>
              <div className='supporter-card'>
                <img src={cmtv} alt='pic' className='supporter-image' />
              </div>
              <div className='supporter-card'>
                <img src={esel} alt='pic' className='supporter-image' />
              </div>
              <div className='supporter-card'>
                <img src={farmacia2p} alt='pic' className='supporter-image' />
              </div>
            </div>
            <div className='supporters-row'>
              <div className='supporter-card'>
                <img src={gil} alt='pic' className='supporter-image' />
              </div>
              <div className='supporter-card'>
                <img src={joana} alt='pic' className='supporter-image' />
              </div>
              <div className='supporter-card'>
                <img src={serenaindade} alt='pic' className='supporter-image' />
              </div>
            </div>
            <div className='supporters-row'>
              <div className='supporter-card'>
                <img
                  src={freguesia_doisportos}
                  alt='pic'
                  className='supporter-image'
                />
                <img
                  src={freguesia_runa}
                  alt='pic'
                  className='supporter-image'
                />
              </div>
              <div className='supporter-card'>
                <img
                  src={freguesia_smsm}
                  alt='pic'
                  className='supporter-image'
                />
                <img
                  src={freguesia_spst}
                  alt='pic'
                  className='supporter-image'
                />
                <img
                  src={freguesia_matacaes}
                  alt='pic'
                  className='supporter-image'
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footerbar />
    </div>
  );
};

export default Supporters;
