import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import pic from "../../assets/images/picture/wink.jpeg";
import fernanda from "../../assets/images/picture/fernanda.jpeg";
import ricardo from "../../assets/images/picture/ricardo.jpeg";
import vania from "../../assets/images/picture/vania.jpeg";
import joao from "../../assets/images/picture/joao.jpg";

import "./team.css";
import { Container } from "react-bootstrap";

const Team = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <Container className='test_content'>
        <div className='team-content'>
          <div className='team-header'>
            <h1 className='team-title'>{"Equipa"}</h1>
          </div>
          <div className='members_grid'>
            <div className='member_card'>
              <img src={vania} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>Vânia Henriques</b>
                </h6>
                <p>Técnica de análises clínicas e saúde pública</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={ricardo} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>Ricardo Fernandes</b>
                </h6>
                <p>Técnico de análises clínicas e saúde pública</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={fernanda} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>Fernanda Henriques</b>
                </h6>
                <p>Enfermeira</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={joao} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
            <div className='member_card'>
              <img src={pic} alt='pic' className='member_foto' />
              <div className='member_label'>
                <h6>
                  <b>João Silva</b>
                </h6>
                <p>Engenheiro Informático</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footerbar />
    </div>
  );
};

export default Team;
