import React from "react";
import { NavigationBar, Footerbar } from "../../../layout";
import logo from "../../../assets/images/logo.png";

import "./program.css";
import { Container } from "react-bootstrap";

const Program = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <Container className='test_content'>
        <div className='program-text'>
          <p>Projecto Saud'Ouro</p>
          <h1>Promoção de Estilos de Vida Saudavel</h1>
          <p>
            {
              "O Programa Saúd’ouro é um Programa de promoção de estilos de vida saudáveis na comunidade, dirigido a adultos, desde que tenham uma condição de saúde ajustada para a participação. Este projeto, dinamizado pela Associação Cultural, Recreativa e Desportiva do Furadouro (ACRDF), pretende desenvolver e avaliar uma intervenção multidisciplinar, sistematizada e individualizada, de reeducação de hábitos de vida saudáveis na comunidade."
            }
          </p>
          <p>
            {
              "O Programa Saúd’ouro assenta no princípio da literacia em saúde e do empowerment, com ênfase no autocuidado. Está suportado pelos seguintes pilares:"
            }
          </p>
          <li>Exercício regular</li>
          <li>Alimentação equilibrada</li>
          <li>Vigilância de saúde</li>
          <li>Auto-gestão (esforço individual)</li>
        </div>
        <div className='program-logo-card'>
          <img src={logo} alt='logo' className='program-image' />
        </div>
      </Container>
      <Footerbar />
    </div>
  );
};

export default Program;
