import React from "react";

import { Container, Nav, Navbar } from "react-bootstrap";

import { FaFacebookSquare } from "react-icons/fa";
import "./footerbar.css";

class FooterBar extends React.Component {
  render() {
    return (
      <Navbar fixed='bottom' bg='light'>
        <Container>
          <Nav className='justify-content-center' style={{ width: "100%" }}>
            <Nav.Item>
              <a
                href='https://www.facebook.com/saudouro'
                className='face-button'
              >
                <FaFacebookSquare size={30} />
              </a>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    );
  }
}

export default FooterBar;
