import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import icon from "../../assets/images/favicon.ico";

class NavigationBar extends React.Component {
  render() {
    return (
      <Navbar collapseOnSelect fixed='top' expand='lg' bg='light'>
        <Container>
          <Navbar.Brand href='/'>
            <img
              alt=''
              src={icon}
              width='30'
              height='30'
              className='d-inline-block align-top'
            />{" "}
            Saud'Ouro
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse
            className='justify-content-end'
            style={{ width: "100%" }}
          >
            <Nav>
              <NavDropdown title='Projecto' id='collasible-nav-dropdown'>
                <NavDropdown.Item as={Link} to='/projecto'>
                  O que somos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/equipa'>
                  Quem somos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/parceiros'>
                  Onde estamos
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to='/apoios'>
                  Quem no apoia
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title='Programas' id='collasible-nav-dropdown'>
                <NavDropdown.Item
                  disabled={true}
                  as={Link}
                  to='/programa/22_23'
                >
                  {"+Autoestima"}
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to='/actividades'>
                Actividades
              </Nav.Link>
              <Nav.Link disabled={true} as={Link} to='/parceiros'>
                Divulgação Científica
              </Nav.Link>
              <Button disabled={true} href='/login' variant='outline-secondary'>
                Iniciar Sessão
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavigationBar;
