import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import "./cientific_divulgation.css";

const CientificDivulgation = () => {
  return (
    <div className='page'>
      <NavigationBar />
      <div className='default-background'>
        <div className='content'>
          <div className='history'>
            <div className='history-title-box'>
              <div className='history-title'>
                <h1>História</h1>
              </div>
            </div>
            <div className='history-description-box'>
              <div className='history-description'>
                <h3>Porque existimos?</h3>
                <p>
                  A evidência científica mostra que é possível prevenir a
                  maioria doenças crónicas não transmissíveis ou alterar o seu
                  curso, melhorando o prognóstico e qualidade de vida, através
                  de ações de prevenção e controle dos principais fatores de
                  risco, com destaque para a alimentação inadequada, o
                  sedentarismo, a hipertensão arterial, a obesidade, o tabagismo
                  e o consumo abusivo de álcool, gorduras e açucares.
                </p>
                <h3>Funcionamento</h3>
                <p>
                  O programa tem uma duração de 8 meses. Durante este período os
                  inscritos terão aulas de treino personalizado, acompanhamento
                  nutricional, orientações para desempenho autónomo e vigilância
                  de saúde.
                </p>
                <h3>A quem se destina</h3>
                <p>
                  Homens e mulheres de todas as idades, desde que tenham uma
                  condição de saúde ajustada para a participação no programa.
                  Esta avaliação será feita pelo médico, suportada por uma
                  equipa de profissionais de saúde. Restrições alimentares e de
                  exercícios serão adaptadas a cada pessoa, de acordo com as
                  suas necessidades individuais.
                </p>
                <h3>Objetivos</h3>
                <ul>
                  <li style={{ fontWeight: "bold" }}>Geral</li>
                  <ul>
                    <li>
                      Capacitar os participantes para a adoção e autogestão de
                      estilos de vida saudáveis
                    </li>
                  </ul>
                  <li style={{ fontWeight: "bold" }}>Específicos</li>
                  <ul>
                    <li>Melhorar os níveis de literacia</li>
                    <li>Melhorar o perfil tensional</li>
                    <li>Melhorar o perfil bioquímico</li>
                    <li>Melhorar a qualidade da alimentação</li>
                    <li>Adoptar hábitos de exercício físico regular</li>
                    <li>Reduzir o risco de desenvolver diabetes a 10 anos</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CientificDivulgation;
