import React from "react";
import { Fade } from "react-slideshow-image";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import "react-slideshow-image/dist/styles.css";
import "./slideshow.css";
import slide1 from "../../assets/images/slides/slide1.jpg";
import slide2 from "../../assets/images/slides/slide2.jpg";
import slide3 from "../../assets/images/slides/slide3.jpg";
import slide4 from "../../assets/images/slides/slide4.jpg";
import slide5 from "../../assets/images/slides/slide5.jpg";
import slide6 from "../../assets/images/slides/slide6.jpg";
import slide7 from "../../assets/images/slides/slide7.jpg";
import slide8 from "../../assets/images/slides/slide8.jpg";
import slide9 from "../../assets/images/slides/slide9.jpg";
import slide10 from "../../assets/images/slides/slide10.jpg";

const slideImages = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  slide10,
];

const buttonStyle = {
  width: "30px",
  background: "none",
  border: "0px",
};

const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <SlArrowLeft className='indicator' />
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <SlArrowRight className='indicator' />
    </button>
  ),
};

const SlideShow = () => {
  return (
    <div className='slideshow'>
      <Fade autoplay={true} {...properties}>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[0]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[1]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[2]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[3]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[4]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[5]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[6]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[7]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[8]})` }}></div>
        </div>
        <div className='each-slide'>
          <div style={{ backgroundImage: `url(${slideImages[9]})` }}></div>
        </div>
      </Fade>
    </div>
  );
};

export default SlideShow;
