import { HashRouter, Routes, Route } from "react-router-dom";

import {
  HomePage,
  Project,
  Program,
  Activities,
  Team,
  Login,
  Partners,
  Supporters,
  CientificDivulgation,
} from "./pages";

import "./App.css";

const App = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/projecto' element={<Project />} />
          <Route path='/programa/22_23' element={<Program />} />
          <Route path='/actividades' element={<Activities />} />
          <Route
            path='/divulgacao_cientifica'
            element={<CientificDivulgation />}
          />
          <Route path='/parceiros' element={<Partners />} />
          <Route path='/apoios' element={<Supporters />} />
          <Route path='/equipa' element={<Team />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </HashRouter>
    </>
  );
};

export default App;
