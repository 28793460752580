import React from "react";
import Container from "react-bootstrap/Container";
import { NavigationBar, Footerbar } from "../../layout";

import "./login.css";

export function Login() {
  return (
    <div className='home_bk'>
      <NavigationBar />
      <div className='home'>
        <Container>
          <div className='resumo'>
            <div className='titulo'>
              <span>Um Programa para sim</span>
            </div>
            <div className='descricao'>
              <span>Era uma vez uma batata, era uma batata frita</span>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
