import React from "react";
import { NavigationBar, Footerbar } from "../../layout";

import video from "../../assets/video/video.mp4";

import "./home.css";
import { SlideShow } from "../../components";
import { Container } from "react-bootstrap";

const HomePage = () => {
  return (
    <>
      <div className='page'>
        <NavigationBar />
        <video src={video} autoPlay loop muted />
        <Container className='test_content'>
          <div className='home_slogan'>
            <h1 className='home_title'>
              Escolha <span className='word_beauty'>hoje</span> um
            </h1>
            <h1 className='home_title'>
              estilo de <span className='word_beauty'>vida saudável</span>
            </h1>
          </div>
          <div className='home_slides'>
            <div className='home_slide'>
              <SlideShow />
            </div>
          </div>
        </Container>
        <Footerbar />
      </div>
    </>
  );
};

export default HomePage;
